/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Form = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://api.miniextensions.com/v1/iframe-embed/DSkc9z4YBXohfEy0Fo67.js?absoluteShareUrl=https%3A%2F%2Fapp.miniextensions.com%2Fform%2FDSkc9z4YBXohfEy0Fo67";
    document.body.appendChild(script);
  }, []);

  return (
    <Box>
      <Box marginBottom={2}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          gutterBottom
          align={'center'}
        >
          New Affiliate Partner Signup
        </Typography>
        <Box sx={{
          margin: 'auto',
          maxWidth: 600
        }}>
          <Typography color="text.secondary" align={'center'}>
            Thank you for deciding to be a new affiliate partner, an exciting opportunity to 
            generate significant income by helping 1000's of small businesses get access to money
            they are owed via the Employee Retention Credit (ERC) program.
          </Typography>
        </Box>

      </Box>
      <Box
        maxWidth={600}
        margin={'0 auto'}
        sx={{
          '& .MuiOutlinedInput-root.MuiInputBase-multiline': {
            padding: 0,
          },
          '& .MuiOutlinedInput-input': {
            background: theme.palette.background.paper,
            padding: 2,
          },
        }}
      >
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12}>
          <div id="miniextensions-iframe-embed-DSkc9z4YBXohfEy0Fo67"></div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Form;
